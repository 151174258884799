import React, { useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Box,
  Button as MuiButton,
  CardContent,
  FormControl as MuiFormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { GenderEnum, UserDTO } from "../../types/user";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputMask from "react-input-mask";
import { Measurement } from "../../types/measurement";
import { MeasurementsLabel } from "./measurementsLabel";
import { calculateAge } from "../../utils/cammon.utils";
import UserAutocomplete from "../../components/autocomplete/UserAutocomplete";
import ImagePicker from "../../components/ImagePicker";
import healthQuestionnairesService from "../../services/HealthQuestionnairesService";
import { HealthQuestionnaire } from "../../types/health-questionnaire";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
type MeasurementForm = {
  date: Date;
  user?: UserDTO;
  gender: string;
  age: string | number;
  height: string | number;
  weight: string | number;
  frontImage: string;
  sideImage: string;
};

const Button = styled(MuiButton)<ButtonProps>(spacing);

const getValidationSchema = (
  imageMeasurementRequired: boolean,
  disableUserMeasurementImage: boolean
) => {
  let schema: any = {
    date: Yup.string().required("Required"),
    user: Yup.object().test(
      "has-properties",
      "User is required",
      (value) => value && Object.keys(value).length > 0
    ),
    gender: Yup.string().required("Required"),
    age: Yup.string().required("Required"),
    height: Yup.string()
      .required("Required")
      .test(
        "is-height-valid",
        "Height must be between 59.1 and 86.6 inches",
        (value) => {
          const height = parseFloat(value);
          return height >= 59.1 && height <= 86.6;
        }
      ),
    weight: Yup.string()
      .required("Required")
      .test(
        "is-weight-valid",
        "Weight must be between 66.1 and 440 lbs",
        (value) => {
          const weight = parseFloat(value);
          return weight >= 66.1 && weight <= 440;
        }
      ),
  };

  if (imageMeasurementRequired && !disableUserMeasurementImage) {
    schema.frontImage = Yup.string().required("Required");
    schema.sideImage = Yup.string().required("Required");
  }

  return Yup.object().shape(schema);
};

type CreateMeasurementFormProps = {
  measurement?: Measurement;
  onSubmit: (measurement: Measurement) => void;
  onCancel: () => void;
};

function CreateMeasurementForm({
  measurement,
  onSubmit,
  onCancel,
}: CreateMeasurementFormProps): JSX.Element {
  const [questionnaire, setQuestionnaire] = useState<HealthQuestionnaire>();

  let initialValues: MeasurementForm = {
    date: (measurement && new Date(measurement?.date)) || new Date(),
    user: measurement?.user || undefined,
    gender: measurement?.gender || "",
    age: measurement ? measurement?.age?.toString() : "",
    height: measurement ? measurement?.height?.toString() : "",
    weight: measurement ? measurement?.weight?.toString() : "",
    frontImage: measurement?.frontImage || "",
    sideImage: measurement?.sideImage || "",
  };
  const formLabels = MeasurementsLabel;

  function handleSubmit(values: MeasurementForm) {
    const preparedData: Measurement = {
      date: values.date,
      user: values.user,
      gender: values.gender.toLowerCase(),
      age: Number(values.age),
      height: Number(values.height),
      weight: Number(values.weight),
      frontImage: values.frontImage,
      sideImage: values.sideImage,
      bodyFatPercentage: null as unknown as number,
      fatWeight: null as unknown as number,
      leanBodyMass: null as unknown as number,
      lbmiTofbmiRatio: null as unknown as number,
      bodyMassIndex: null as unknown as number,
      waistToHeightRatio: null as unknown as number,
      waistToHipRatio: null as unknown as number,
      recommendedCalories: null as unknown as number,
      recommendedProtein: null as unknown as number,
      recommendedWater: null as unknown as number,
      fitnessScore: null as unknown as number,
      wellnessScore: null as unknown as number,
      workoutScore: null as unknown as number,
      vitalityScore: null as unknown as number,
    } as unknown as Measurement;
    onSubmit(preparedData);
  }

  function changeUserHandler(
    user: UserDTO | null,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) {
    setFieldValue("user", user);
    setFieldValue("gender", user?.gender);
    setFieldValue(
      "age",
      calculateAge(user?.birthDate || new Date()).toString()
    );
    setFieldValue("height", user?.height.toString());
    if (user?.id) {
      healthQuestionnairesService
        .getByUserIdWithLastModifiedDate(user.id)
        .then((questionnaire) => setQuestionnaire(questionnaire));
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={getValidationSchema(
        !!questionnaire?.imageMeasurement,
        !!questionnaire?.user?.company?.disableUserMeasurementImage
      )}
      onSubmit={handleSubmit}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
      }) => {
        const handleDecimalInputChange = (
          event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          field: string,
          maxLength: number = 5
        ) => {
          const inputValue = event.target.value.replace(/[^0-9.]/g, "");
          const validInput = inputValue.replace(/(\..*)\./g, "$1");
          const limitedInput = validInput.slice(0, maxLength);
          setFieldValue(field, limitedInput);
        };

        return (
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid mt={2} container spacing={6}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    {formLabels["date"]}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      label="Date"
                      value={values.date}
                      onChange={(date) => setFieldValue("date", date)}
                      slotProps={{
                        textField: {
                          variant: "outlined",
                          fullWidth: true,
                          error: Boolean(touched.date && errors.date),
                        },
                      }}
                    />
                  </DemoContainer>
                </Grid>
              </Grid>

              <Grid mt={2} container spacing={6}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    {formLabels["user"]}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <UserAutocomplete
                    id="user-select"
                    label="User"
                    selectedOption={values.user}
                    onOptionSelect={(value) =>
                      changeUserHandler(value, setFieldValue)
                    }
                    renderOptionLabel={(option) =>
                      `${option.firstName} ${option.lastName}`
                    }
                    isTouched={!!touched.user}
                    error={errors.user || ""}
                  />
                </Grid>
              </Grid>

              <Grid mt={2} container spacing={6}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    {formLabels["gender"]}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    my={2}
                    fullWidth
                    error={Boolean(touched.gender && errors.gender)}
                  >
                    <InputLabel id="gender-select-label">Gender</InputLabel>
                    <Select
                      name="gender"
                      labelId="gendere-select-label"
                      id="gender-select"
                      value={values.gender}
                      label="Gender"
                      onBlur={handleBlur}
                      onChange={(e) => setFieldValue("gender", e.target.value)}
                    >
                      {Object.entries(GenderEnum).map(([key, value]) => (
                        <MenuItem key={`gender-${key}`} value={key}>
                          {value}
                        </MenuItem>
                      ))}
                    </Select>
                    {touched.gender && errors.gender && (
                      <FormHelperText error>{errors.gender}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid mt={2} container spacing={6}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    {formLabels["age"]}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputMask
                    mask="999"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.age}
                    maskPlaceholder={""}
                  >
                    <TextField
                      name="age"
                      label="Age"
                      fullWidth
                      error={Boolean(touched.age && errors.age)}
                      helperText={touched.age && errors.age}
                      variant="outlined"
                      my={2}
                    />
                  </InputMask>
                </Grid>
              </Grid>

              <Grid mt={2} container spacing={6}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    {formLabels["height"]}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="height"
                    label="Height"
                    onChange={(event) =>
                      handleDecimalInputChange(event, "height")
                    }
                    onBlur={handleBlur}
                    value={values.height}
                    fullWidth
                    error={Boolean(touched.height && errors.height)}
                    helperText={touched.height && errors.height}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>

              <Grid mt={2} container spacing={6}>
                <Grid item xs={6}>
                  <Typography variant="subtitle1" gutterBottom>
                    {formLabels["weight"]}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    name="weight"
                    label="Weight"
                    onChange={(event) =>
                      handleDecimalInputChange(event, "weight")
                    }
                    onBlur={handleBlur}
                    value={values.weight}
                    fullWidth
                    error={Boolean(touched.weight && errors.weight)}
                    helperText={touched.weight && errors.weight}
                    variant="outlined"
                    my={2}
                  />
                </Grid>
              </Grid>

              {!questionnaire?.user?.company?.disableUserMeasurementImage &&
              questionnaire?.imageMeasurement ? (
                <>
                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["frontImage"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <ImagePicker
                        name="frontImage"
                        label="Upload Front Image"
                        value={values.frontImage}
                        error={Boolean(touched.frontImage && errors.frontImage)}
                        helperText={
                          touched.frontImage ? errors.frontImage : undefined
                        }
                        onFileChange={(name, value) => {
                          setFieldValue("frontImage", value);
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>

                  <Grid mt={2} container spacing={6}>
                    <Grid item xs={6}>
                      <Typography variant="subtitle1" gutterBottom>
                        {formLabels["sideImage"]}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <ImagePicker
                        name="sideImage"
                        label="Upload Side Image"
                        value={values.sideImage}
                        error={Boolean(touched.sideImage && errors.sideImage)}
                        helperText={
                          touched.sideImage ? errors.sideImage : undefined
                        }
                        onFileChange={(name, value) => {
                          setFieldValue("sideImage", value);
                        }}
                        onBlur={handleBlur}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : null}

              <Box
                component="div"
                display="flex"
                justifyContent="flex-end"
                mt={6}
              >
                <Button
                  variant="outlined"
                  color="error"
                  mt={3}
                  mr={5}
                  onClick={onCancel}
                >
                  Cancel
                </Button>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Save
                </Button>
              </Box>
            </form>
          </CardContent>
        );
      }}
    </Formik>
  );
}

export default CreateMeasurementForm;
