import React, { useState } from "react";

import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { MoreHoriz as MoreHorizIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Reward } from "../../types/reward";
import { format } from "date-fns";
import { PointsType, TransactionType } from "../../enums/reward";
import {
  GridCellParams,
  GridColDef,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import {
  currentDateOperator,
  dateRangeOperator,
} from "../../utils/filters/dateField";
import { textEqualsOperator } from "../../utils/filters/textFild";
import Table from "../../components/Table";
import { multiSelectOperator } from "../../utils/filters/multiSelectOperator";
import { generateOptionsFromEnum } from "../../utils/generateOptionsFromEnum";

type RewardsTableProps = {
  rewards: Reward[];
  count: number;
  loading: boolean;
  getRewards: (
    page: number,
    size: number,
    filter: string,
    search: string,
    sortOrder: string,
    sortField: string
  ) => void;
  setPageNumber: (pageNumber: number) => void;
  setElementsPerPage: (numberPerPage: number) => void;
  updateReward: (reward: Reward) => void;
  removeReward: (reward: Reward) => void;
};

const RewardsTable: React.FC<RewardsTableProps> = ({
  rewards,
  count,
  loading,
  getRewards,
  setPageNumber,
  setElementsPerPage,
  updateReward,
  removeReward,
}) => {
  const [anchorEl, setAnchorEl] = useState<{
    [key: number]: HTMLElement | null;
  }>({});
  const navigate = useNavigate();

  const handleDetailButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ ...anchorEl, [id]: event.currentTarget });
  };
  const handleMenuClose = (id: number) => {
    setAnchorEl({ ...anchorEl, [id]: null });
  };

  const handleUpdateClick = (
    event: React.MouseEvent<HTMLLIElement>,
    reward: Reward
  ) => {
    event.stopPropagation();
    handleMenuClose(reward.id!);
    updateReward(reward);
  };

  const handleRemoveClick = (
    event: React.MouseEvent<HTMLLIElement>,
    reward: Reward
  ) => {
    event.stopPropagation();
    handleMenuClose(reward.id!);
    removeReward(reward);
  };

  const handleRowClick = (params: GridCellParams) => {
    if (params.colDef.field !== "actions") {
      return navigate(`/private/rewards/${params.row.id}/details`);
    }
  };

  const rows = rewards.map((reward) => ({
    ...reward,
    company: reward?.user?.company,
    email: reward?.user?.email,
  }));

  const pointsTypeOptions = generateOptionsFromEnum(PointsType);
  const transactionTypeOptions = generateOptionsFromEnum(TransactionType);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Reward ID",
      width: 100,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "transactionDate",
      headerName: "Reward Transaction Date",
      width: 175,
      filterOperators: [dateRangeOperator, currentDateOperator],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return format(new Date(value), "MM-dd-yyyy");
      },
    },
    {
      field: "transactionType",
      headerName: "Reward Transaction",
      flex: 1,
      filterOperators: [multiSelectOperator(transactionTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return TransactionType[value as keyof typeof TransactionType];
      },
    },
    {
      field: "company",
      headerName: "Company Name",
      flex: 1,
      filterable: false,
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return value?.name;
      },
    },
    {
      field: "user",
      headerName: "User",
      flex: 1,
      filterOperators: [dateRangeOperator, currentDateOperator],
      sortingOrder: ["desc", "asc"],
      valueGetter: (params) =>
        `${params.row?.user?.firstName} ${params.row?.user?.lastName}` || "",
    },
    {
      field: "email",
      headerName: "Email Address",
      flex: 1,
      filterable: false,
      sortable: false,
    },
    {
      field: "points",
      headerName: "Reward Points",
      flex: 1,
      filterOperators: [multiSelectOperator(pointsTypeOptions)],
      sortingOrder: ["desc", "asc"],
      valueFormatter: ({ value }: GridValueFormatterParams) => {
        return PointsType[value as keyof typeof PointsType];
      },
    },
    {
      field: "balance",
      headerName: "Reward Balance",
      flex: 1,
      filterOperators: [textEqualsOperator],
      sortingOrder: ["desc", "asc"],
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params: GridCellParams) => {
        const reward = params.row as Reward;
        return (
          <Box component="div" mr={1}>
            <IconButton
              aria-owns={anchorEl[reward.id!] ? "simple-menu" : undefined}
              aria-haspopup="true"
              aria-label="details"
              size="large"
              onClick={(event) => handleDetailButtonClick(event, reward.id!)}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl[reward.id!]}
              open={Boolean(anchorEl[reward.id!])}
              onClose={() => handleMenuClose(reward.id!)}
            >
              <MenuItem onClick={(event) => handleUpdateClick(event, reward)}>
                Edit
              </MenuItem>
              <MenuItem onClick={(event) => handleRemoveClick(event, reward)}>
                Remove
              </MenuItem>
            </Menu>
          </Box>
        );
      },
    },
  ];

  return (
    <Table
      id="rewards"
      rows={rows}
      columns={columns}
      count={count}
      loading={loading}
      getData={getRewards}
      setPageNumber={setPageNumber}
      setElementsPerPage={setElementsPerPage}
      orderByField="id"
      onCellClick={handleRowClick}
    />
  );
};

export default RewardsTable;
