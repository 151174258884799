import React, { useLayoutEffect, useState } from "react";
import * as Yup from "yup";
import styled from "@emotion/styled";
import { Formik } from "formik";

import {
  Backdrop,
  Box,
  Button as MuiButton,
  CardContent,
  CircularProgress,
  FormControl as MuiFormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField as MuiTextField,
  Typography,
} from "@mui/material";
import { spacing, SpacingProps } from "@mui/system";
import { GenderEnum, UserDTO } from "../../types/user";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputMask from "react-input-mask";
import { MeasurementDTO } from "../../types/measurement";
import { MeasurementsLabel } from "./measurementsLabel";
import { calculateAge } from "../../utils/cammon.utils";
import { Program } from "../../types/program";
import UserAutocomplete from "../../components/autocomplete/UserAutocomplete";
import ImagePicker from "../../components/ImagePicker";
import { roundedNumber } from "../../utils/roundedNumber";
import MeasurementsService from "../../services/MeasurementsService";
import ProgramAutocomplete from "../../components/autocomplete/ProgramAutocomplete";

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);
const FormControl = styled(MuiFormControl)(spacing);

interface ButtonProps extends SpacingProps {
  component?: string;
}
type MeasurementForm = {
  date: Date;
  program?: Program;
  user?: UserDTO;
  gender: string;
  age: string | number;
  height: string | number;
  weight: string | number;
  frontImage: string;
  isNewFrontImage: string;
  sideImage: string;
  isNewSideImage: string;
  neck: string;
  elbow: string;
  wrist: string;
  chest: string;
  waist: string;
  hip: string;
  thigh: string;
  knee: string;
  calf: string;
  ankle: string;
  bodyFatPercentage: string;
  fatWeight: string;
  leanBodyMass: string;
  lbmiTofbmiRatio: string;
  bodyMassIndex: string;
  waistToHeightRatio: string;
  waistToHipRatio: string;
  recommendedCalories: string;
  recommendedProtein: string;
  recommendedWater: string;
  fitnessScore: string;
  wellnessScore: string;
  workoutScore: string;
};

const Button = styled(MuiButton)<ButtonProps>(spacing);

const StyledBackdrop = styled(Backdrop)`
  color: #376fd0;
  z-index: ${(props) => props.theme.zIndex.drawer + 1};
`;

const validationSchema = Yup.object().shape({
  date: Yup.string().required("Required"),
  user: Yup.object().test(
    "has-properties",
    "User is required",
    (value) => value && Object.keys(value).length > 0
  ),
  gender: Yup.string().required("Required"),
  age: Yup.string().required("Required"),
  height: Yup.string()
    .required("Required")
    .test(
      "is-height-valid",
      "Height must be between 59.1 and 86.6 inches",
      (value) => {
        const height = parseFloat(value);
        return height >= 59.1 && height <= 86.6;
      }
    ),
  weight: Yup.string()
    .required("Required")
    .test(
      "is-weight-valid",
      "Weight must be between 66.1 and 440 lbs",
      (value) => {
        const weight = parseFloat(value);
        return weight >= 66.1 && weight <= 440;
      }
    ),

  neck: Yup.string().required("Required"),
  elbow: Yup.string().required("Required"),
  wrist: Yup.string().required("Required"),
  chest: Yup.string().required("Required"),
  waist: Yup.string().required("Required"),
  hip: Yup.string().required("Required"),
  thigh: Yup.string().required("Required"),
  knee: Yup.string().required("Required"),
  calf: Yup.string().required("Required"),
  ankle: Yup.string().required("Required"),
});

type EditMeasurementFormProps = {
  measurementId?: number;
  onSubmit: (measurement: MeasurementDTO) => void;
  onCancel: () => void;
};

function EditMeasurementForm({
  measurementId,
  onSubmit,
  onCancel,
}: EditMeasurementFormProps): JSX.Element {
  const [measurement, setMeasurement] = useState<MeasurementDTO>();
  const [isLoading, setIsLoading] = useState(false);

  useLayoutEffect(() => {
    if (measurementId) {
      setIsLoading(true);
      MeasurementsService.getById(measurementId).then((resp) => {
        setIsLoading(false);
        setMeasurement(resp);
      });
    }
  }, [measurementId]);

  let initialValues: MeasurementForm = {
    date: (measurement && new Date(measurement?.date)) || new Date(),
    program: measurement?.program || undefined,
    user: measurement?.user || undefined,
    gender: measurement?.gender || "",
    age: measurement ? measurement?.age?.toString() : "",
    height: measurement ? measurement?.height?.toString() : "",
    weight: measurement ? measurement?.weight?.toString() : "",
    frontImage: measurement?.frontImage || "",
    isNewFrontImage: "",
    sideImage: measurement?.sideImage || "",
    isNewSideImage: "",

    neck: roundedNumber(measurement?.volumeParams?.neck)?.toString() || "",
    elbow:
      roundedNumber(measurement?.volumeParams?.elbowGirth)?.toString() || "",
    wrist: roundedNumber(measurement?.volumeParams?.wrist)?.toString() || "",
    chest: roundedNumber(measurement?.volumeParams?.chest)?.toString() || "",
    waist: roundedNumber(measurement?.volumeParams?.waist)?.toString() || "",
    hip: roundedNumber(measurement?.volumeParams?.highHips)?.toString() || "",
    thigh: roundedNumber(measurement?.volumeParams?.thigh)?.toString() || "",
    knee: roundedNumber(measurement?.volumeParams?.knee)?.toString() || "",
    calf: roundedNumber(measurement?.volumeParams?.calf)?.toString() || "",
    ankle: roundedNumber(measurement?.volumeParams?.ankle)?.toString() || "",
    bodyFatPercentage:
      roundedNumber(measurement?.bodyFatPercentage, 4)?.toString() || "",
    fatWeight: roundedNumber(measurement?.fatWeight)?.toString() || "",
    leanBodyMass: roundedNumber(measurement?.leanBodyMass)?.toString() || "",
    lbmiTofbmiRatio:
      roundedNumber(measurement?.lbmiTofbmiRatio)?.toString() || "",
    bodyMassIndex: roundedNumber(measurement?.bodyMassIndex)?.toString() || "",
    waistToHeightRatio:
      roundedNumber(measurement?.waistToHeightRatio)?.toString() || "",
    waistToHipRatio:
      roundedNumber(measurement?.waistToHipRatio)?.toString() || "",
    recommendedCalories:
      roundedNumber(measurement?.recommendedCalories)?.toString() || "",
    recommendedProtein:
      roundedNumber(measurement?.recommendedProtein)?.toString() || "",
    recommendedWater:
      roundedNumber(measurement?.recommendedWater)?.toString() || "",
    fitnessScore: roundedNumber(measurement?.fitnessScore)?.toString() || "",
    wellnessScore: roundedNumber(measurement?.wellnessScore)?.toString() || "",
    workoutScore: roundedNumber(measurement?.workoutScore)?.toString() || "",
  };
  const formLabels = MeasurementsLabel;

  function handleSubmit(values: MeasurementForm) {
    const preparedData: MeasurementDTO = {
      ...measurement,
      date: values.date,
      user: values.user,
      program: values.program,
      gender: values.gender,
      age: Number(values.age),
      height: Number(values.height),
      weight: Number(values.weight),
      frontImage: values.frontImage,
      sideImage: values.sideImage,
      recalculateImage: values.isNewFrontImage || values.isNewSideImage,
      volumeParams: {
        neck: Number(values.neck),
        elbowGirth: Number(values.elbow),
        wrist: Number(values.wrist),
        chest: Number(values.chest),
        waist: Number(values.waist),
        highHips: Number(values.hip),
        thigh: Number(values.thigh),
        knee: Number(values.knee),
        calf: Number(values.calf),
        ankle: Number(values.ankle),
      },
      bodyFatPercentage: Number(values.bodyFatPercentage),
      fatWeight: Number(values.fatWeight),
      leanBodyMass: Number(values.leanBodyMass),
      lbmiTofbmiRatio: Number(values.lbmiTofbmiRatio),
      bodyMassIndex: Number(values.bodyMassIndex),
      waistToHeightRatio: Number(values.waistToHeightRatio),
      waistToHipRatio: Number(values.waistToHipRatio),
      recommendedCalories: Number(values.recommendedCalories),
      recommendedProtein: Number(values.recommendedProtein),
      recommendedWater: Number(values.recommendedWater),
      fitnessScore: Number(values.fitnessScore),
      wellnessScore: Number(values.wellnessScore),
      workoutScore: Number(values.workoutScore),
    } as unknown as MeasurementDTO;

    onSubmit({ ...measurement, ...(preparedData as any) });
  }

  function changeUserHandler(
    user: UserDTO | null,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) {
    setFieldValue("user", user);
    setFieldValue("gender", user?.gender.toLowerCase());
    setFieldValue(
      "age",
      calculateAge(user?.birthDate || new Date()).toString()
    );
    setFieldValue("height", user?.height.toString());
  }

  return (
    <>
      <StyledBackdrop open={isLoading}>
        <CircularProgress color="inherit" />
      </StyledBackdrop>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => {
          const handleDecimalInputChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            field: string
          ) => {
            const inputValue = event.target.value.replace(/[^0-9.]/g, "");
            const validInput = inputValue.replace(/(\..*)\./g, "$1");
            setFieldValue(field, validInput);
          };

          const handleHeightAndWeightInputChange = (
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            field: string,
            maxLength: number = 5
          ) => {
            const inputValue = event.target.value.replace(/[^0-9.]/g, "");
            const validInput = inputValue.replace(/(\..*)\./g, "$1");
            const limitedInput = validInput.slice(0, maxLength);
            setFieldValue(field, limitedInput);
          };

          return (
            <CardContent>
              <form onSubmit={handleSubmit}>
                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["date"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        label="Date"
                        value={values.date}
                        onChange={(date) => setFieldValue("date", date)}
                        slotProps={{
                          textField: {
                            variant: "outlined",
                            fullWidth: true,
                            error: Boolean(touched.date && errors.date),
                          },
                        }}
                      />
                    </DemoContainer>
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["user"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <UserAutocomplete
                      id="user-select"
                      label="User"
                      selectedOption={values.user}
                      onOptionSelect={(value) =>
                        changeUserHandler(value, setFieldValue)
                      }
                      renderOptionLabel={(option) =>
                        `${option.firstName} ${option.lastName}`
                      }
                      isTouched={!!touched.user}
                      error={errors.user || ""}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["gender"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      my={2}
                      fullWidth
                      error={Boolean(touched.gender && errors.gender)}
                    >
                      <InputLabel id="gender-select-label">Gender</InputLabel>
                      <Select
                        name="gender"
                        labelId="gendere-select-label"
                        id="gender-select"
                        value={values.gender}
                        label="Gender"
                        onBlur={handleBlur}
                        onChange={(e) =>
                          setFieldValue("gender", e.target.value)
                        }
                      >
                        {Object.entries(GenderEnum).map(([key, value]) => (
                          <MenuItem
                            key={`gender-${key}`}
                            value={key.toLowerCase()}
                          >
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.gender && errors.gender && (
                        <FormHelperText error>{errors.gender}</FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["age"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <InputMask
                      mask="999"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.age}
                      maskPlaceholder={""}
                    >
                      <TextField
                        name="age"
                        label="Age"
                        fullWidth
                        error={Boolean(touched.age && errors.age)}
                        helperText={touched.age && errors.age}
                        variant="outlined"
                        my={2}
                      />
                    </InputMask>
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["height"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="height"
                      label="Height"
                      onChange={(event) =>
                        handleHeightAndWeightInputChange(event, "height")
                      }
                      onBlur={handleBlur}
                      value={values.height}
                      fullWidth
                      error={Boolean(touched.height && errors.height)}
                      helperText={touched.height && errors.height}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["weight"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="weight"
                      label="Weight"
                      onChange={(event) =>
                        handleHeightAndWeightInputChange(event, "weight")
                      }
                      onBlur={handleBlur}
                      value={values.weight}
                      fullWidth
                      error={Boolean(touched.weight && errors.weight)}
                      helperText={touched.weight && errors.weight}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["frontImage"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <ImagePicker
                      name="frontImage"
                      label="Upload Front Image"
                      value={values.frontImage}
                      error={Boolean(touched.frontImage && errors.frontImage)}
                      helperText={
                        touched.frontImage ? errors.frontImage : undefined
                      }
                      onFileChange={(name, value) => {
                        setFieldValue("frontImage", value);
                      }}
                      onImageStatusChange={(isNew) =>
                        setFieldValue("isNewFrontImage", isNew)
                      }
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["sideImage"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <ImagePicker
                      name="sideImage"
                      label="Upload Side Image"
                      value={values.sideImage}
                      error={Boolean(touched.sideImage && errors.sideImage)}
                      helperText={
                        touched.sideImage ? errors.sideImage : undefined
                      }
                      onFileChange={(name, value) => {
                        setFieldValue("sideImage", value);
                      }}
                      onImageStatusChange={(isNew) =>
                        setFieldValue("isNewSideImage", isNew)
                      }
                      onBlur={handleBlur}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["neck"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="neck"
                      label="Neck"
                      fullWidth
                      onChange={(event) =>
                        handleDecimalInputChange(event, "neck")
                      }
                      onBlur={handleBlur}
                      value={values.neck}
                      error={Boolean(touched.neck && errors.neck)}
                      helperText={touched.neck && errors.neck}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["elbow"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="elbow"
                      label="Elbow"
                      fullWidth
                      onChange={(event) =>
                        handleDecimalInputChange(event, "elbow")
                      }
                      onBlur={handleBlur}
                      value={values.elbow}
                      error={Boolean(touched.elbow && errors.elbow)}
                      helperText={touched.elbow && errors.elbow}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["wrist"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="wrist"
                      label="Wrist"
                      fullWidth
                      onChange={(event) =>
                        handleDecimalInputChange(event, "wrist")
                      }
                      onBlur={handleBlur}
                      value={values.wrist}
                      error={Boolean(touched.wrist && errors.wrist)}
                      helperText={touched.wrist && errors.wrist}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["chest"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="chest"
                      label="Chest"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "chest")
                      }
                      onBlur={handleBlur}
                      value={values.chest}
                      fullWidth
                      error={Boolean(touched.chest && errors.chest)}
                      helperText={touched.chest && errors.chest}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["waist"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="waist"
                      label="Waist"
                      fullWidth
                      onChange={(event) =>
                        handleDecimalInputChange(event, "waist")
                      }
                      onBlur={handleBlur}
                      value={values.waist}
                      error={Boolean(touched.waist && errors.waist)}
                      helperText={touched.waist && errors.waist}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["hip"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="hip"
                      label="Hip"
                      fullWidth
                      onChange={(event) =>
                        handleDecimalInputChange(event, "hip")
                      }
                      onBlur={handleBlur}
                      value={values.hip}
                      error={Boolean(touched.hip && errors.hip)}
                      helperText={touched.hip && errors.hip}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["thigh"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="thigh"
                      label="Thigh"
                      fullWidth
                      onChange={(event) =>
                        handleDecimalInputChange(event, "thigh")
                      }
                      onBlur={handleBlur}
                      value={values.thigh}
                      error={Boolean(touched.thigh && errors.thigh)}
                      helperText={touched.thigh && errors.thigh}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["knee"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="knee"
                      label="Knee"
                      fullWidth
                      onChange={(event) =>
                        handleDecimalInputChange(event, "knee")
                      }
                      onBlur={handleBlur}
                      value={values.knee}
                      error={Boolean(touched.knee && errors.knee)}
                      helperText={touched.knee && errors.knee}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["calf"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="calf"
                      label="Calf"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "calf")
                      }
                      onBlur={handleBlur}
                      value={values.calf}
                      fullWidth
                      error={Boolean(touched.calf && errors.calf)}
                      helperText={touched.calf && errors.calf}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["ankle"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      name="ankle"
                      label="Ankle"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "ankle")
                      }
                      onBlur={handleBlur}
                      value={values.ankle}
                      fullWidth
                      error={Boolean(touched.ankle && errors.ankle)}
                      helperText={touched.ankle && errors.ankle}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["bodyFatPercentage"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="bodyFatPercentage"
                      label="Body Fat Percentage"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "bodyFatPercentage")
                      }
                      onBlur={handleBlur}
                      value={values.bodyFatPercentage}
                      fullWidth
                      error={Boolean(
                        touched.bodyFatPercentage && errors.bodyFatPercentage
                      )}
                      helperText={
                        touched.bodyFatPercentage && errors.bodyFatPercentage
                      }
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["fatWeight"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="fatWeight"
                      label="Fat Weight"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "fatWeight")
                      }
                      onBlur={handleBlur}
                      value={values.fatWeight}
                      fullWidth
                      error={Boolean(touched.fatWeight && errors.fatWeight)}
                      helperText={touched.fatWeight && errors.fatWeight}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["leanBodyMass"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="leanBodyMass"
                      label="Lean Body Mass"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "leanBodyMass")
                      }
                      onBlur={handleBlur}
                      value={values.leanBodyMass}
                      fullWidth
                      error={Boolean(
                        touched.leanBodyMass && errors.leanBodyMass
                      )}
                      helperText={touched.leanBodyMass && errors.leanBodyMass}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["bodyMassIndex"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="bodyMassIndex"
                      label="Body Mass Index"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "bodyMassIndex")
                      }
                      onBlur={handleBlur}
                      value={values.bodyMassIndex}
                      fullWidth
                      error={Boolean(
                        touched.bodyMassIndex && errors.bodyMassIndex
                      )}
                      helperText={touched.bodyMassIndex && errors.bodyMassIndex}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["lbmiTofbmiRatio"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="lbmiTofbmiRatio"
                      label="Lbmi To Fbmi Ratio"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "lbmiTofbmiRatio")
                      }
                      onBlur={handleBlur}
                      value={values.lbmiTofbmiRatio}
                      fullWidth
                      error={Boolean(
                        touched.lbmiTofbmiRatio && errors.lbmiTofbmiRatio
                      )}
                      helperText={
                        touched.lbmiTofbmiRatio && errors.lbmiTofbmiRatio
                      }
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["waistToHeightRatio"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="waistToHeightRatio"
                      label="Waist To Height Ratio"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "waistToHeightRatio")
                      }
                      onBlur={handleBlur}
                      value={values.waistToHeightRatio}
                      fullWidth
                      error={Boolean(
                        touched.waistToHeightRatio && errors.waistToHeightRatio
                      )}
                      helperText={
                        touched.waistToHeightRatio && errors.waistToHeightRatio
                      }
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["waistToHipRatio"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="waistToHipRatio"
                      label="Waist To Hip Ratio"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "waistToHipRatio")
                      }
                      onBlur={handleBlur}
                      value={values.waistToHipRatio}
                      fullWidth
                      error={Boolean(
                        touched.waistToHipRatio && errors.waistToHipRatio
                      )}
                      helperText={
                        touched.waistToHipRatio && errors.waistToHipRatio
                      }
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["recommendedCalories"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="recommendedCalories"
                      label="Recommended Calories"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "recommendedCalories")
                      }
                      onBlur={handleBlur}
                      value={values.recommendedCalories}
                      fullWidth
                      error={Boolean(
                        touched.recommendedCalories &&
                          errors.recommendedCalories
                      )}
                      helperText={
                        touched.recommendedCalories &&
                        errors.recommendedCalories
                      }
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["recommendedProtein"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="recommendedProtein"
                      label="Recommended Protein"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "recommendedProtein")
                      }
                      onBlur={handleBlur}
                      value={values.recommendedProtein}
                      fullWidth
                      error={Boolean(
                        touched.recommendedProtein && errors.recommendedProtein
                      )}
                      helperText={
                        touched.recommendedProtein && errors.recommendedProtein
                      }
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["recommendedWater"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="recommendedWater"
                      label="Recommended Water"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "recommendedWater")
                      }
                      onBlur={handleBlur}
                      value={values.recommendedWater}
                      fullWidth
                      error={Boolean(
                        touched.recommendedWater && errors.recommendedWater
                      )}
                      helperText={
                        touched.recommendedWater && errors.recommendedWater
                      }
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["fitnessScore"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="fitnessScore"
                      label="Fitness Score"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "fitnessScore")
                      }
                      onBlur={handleBlur}
                      value={values.fitnessScore}
                      fullWidth
                      error={Boolean(
                        touched.fitnessScore && errors.fitnessScore
                      )}
                      helperText={touched.fitnessScore && errors.fitnessScore}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["wellnessScore"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="wellnessScore"
                      label="Wellness Score"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "wellnessScore")
                      }
                      onBlur={handleBlur}
                      value={values.wellnessScore}
                      fullWidth
                      error={Boolean(
                        touched.wellnessScore && errors.wellnessScore
                      )}
                      helperText={touched.wellnessScore && errors.wellnessScore}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["workoutScore"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      disabled
                      name="workoutScore"
                      label="Workout Score"
                      onChange={(event) =>
                        handleDecimalInputChange(event, "workoutScore")
                      }
                      onBlur={handleBlur}
                      value={values.workoutScore}
                      fullWidth
                      error={Boolean(
                        touched.workoutScore && errors.workoutScore
                      )}
                      helperText={touched.workoutScore && errors.workoutScore}
                      variant="outlined"
                      my={2}
                    />
                  </Grid>
                </Grid>

                <Grid mt={2} container spacing={6}>
                  <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                      {formLabels["program"]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <ProgramAutocomplete
                      disabled
                      id="program"
                      label="Program"
                      selectedOption={values.program}
                      onOptionSelect={(value) =>
                        setFieldValue("program", value)
                      }
                      isTouched={!!touched.program}
                      error={errors.program || ""}
                    />
                  </Grid>
                </Grid>

                <Box
                  component="div"
                  display="flex"
                  justifyContent="flex-end"
                  mt={6}
                >
                  <Button
                    variant="outlined"
                    color="error"
                    mt={3}
                    mr={5}
                    onClick={onCancel}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    Update
                  </Button>
                </Box>
              </form>
            </CardContent>
          );
        }}
      </Formik>
    </>
  );
}

export default EditMeasurementForm;
